import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MainApiService {
  constructor(
    private httpClient: HttpClient
  ) { }
  protected _accessToken:string = "";
  public accessToken():string {
    return this._accessToken;
  }
  public setAccessToken(token:string) {
    this._accessToken = token;
  }
  public clearAccessToken() {
    this._accessToken = "";
  }
  protected getHttpHeaders():HttpHeaders {
    let headersStr:any = {'Accept':'application/json'};
    if(this._accessToken !== "" && this._accessToken !== undefined) {
      headersStr.Authorization = 'Bearer ' + this._accessToken;
    }
    return new HttpHeaders(headersStr);
  }
  protected postHttpHeaders():HttpHeaders {
    let headersStr:any = {'Accept':'application/json', 'Content-Type':'application/x-www-form-urlencoded'};
    if(this._accessToken !== "" && this._accessToken !== undefined) {
      headersStr.Authorization = 'Bearer ' + this._accessToken;
    }
    return new HttpHeaders(headersStr);
  }
  protected uploadHttpHeaders():HttpHeaders {
    let headersStr:any = {'Accept':'application/json'};
    if(this._accessToken !== "" && this._accessToken !== undefined) {
      headersStr.Authorization = 'Bearer ' + this._accessToken;
    }
    return new HttpHeaders(headersStr);
  }
  protected toQueryString(obj:any):string {
    var str:Array<string> = [];
    if(obj == null || obj == undefined) {
      return str.join("&");
    }
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }
  private getErrorMsg(error:any):string {
    var errorMsg:string = "";
    if (error.error instanceof ErrorEvent) {
        errorMsg = `${error.error.message}`;
    } else {
      if(error.error !== undefined && error.error.error !== undefined) {
        errorMsg = error.error.error
      }
      else if(error.message !== undefined) {
        errorMsg = `${error.message}`;
      }
    }
    return errorMsg;
  }
  oauthToken(username:string, password:string, onSuccess:Function, onError:Function) {
    const requestBody = new HttpParams()
      .set('username', username)
      .set('password', password)
      .set('grant_type', 'password')
      .set('client_id', environment.clientId)
      .set('client_secret', environment.clientSecret) ;
    var request = this.httpClient.post<any>(
      environment.mainApiUrl + "/o/token/",
      requestBody,
      {
        headers:this.postHttpHeaders()
      }
    );
    request.subscribe({
      next: (data:any) => {
        onSuccess(data);
      },
      error: (error:any) => {
        if(error.status == 401) {
          onError("Login / Mot de passe incorrect", error)
        }
        else {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  revokeToken(onSuccess:Function, onError:Function) {
    const requestBody = new HttpParams()
      .set('token', this._accessToken)
      .set('client_id', environment.clientId)
      .set('client_secret', environment.clientSecret) ;
    var request = this.httpClient.post<any>(
      environment.mainApiUrl + "/o/revoke-token/",
      requestBody,
      {
        headers:this.postHttpHeaders()
      }
    );
    request.subscribe({
      next: (data:any) => {
        this._accessToken= ""
        onSuccess(data);
      },
      error: (error:any) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  authenticatedAgent(onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/agents/authenticated/",
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  home(onSuccess:Function, onError:Function):Observable<string> {
    var request = this.httpClient.get<string>(environment.mainApiUrl + "/home");
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  public listPointsFocaux(structureId:string, onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/agents/points_focaux/?structure=" + encodeURIComponent(structureId),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  public listPays(params:any, onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/pays/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  public allPays(params:any, onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/pays/all/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  public detailsPays(paramId?:number, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/pays/" + paramId + "/",
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  addPays(editParams:any, onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.postHttpHeaders() ;
    var request = this.httpClient.post<any>(
      environment.mainApiUrl + "/pays/",
      new HttpParams({ fromObject : editParams }),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  modifyPays(paramId?:number, editParams?:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.postHttpHeaders() ;
    editParams._method = "PUT";
    var request = this.httpClient.put<any>(
      environment.mainApiUrl + "/pays/" + paramId + "/",
      new HttpParams({ fromObject : editParams }),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  deletePays(paramId?:number, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.postHttpHeaders() ;
    var request = this.httpClient.delete<any>(
      environment.mainApiUrl + "/pays/" + paramId + "/",
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }




  public detailsDomainesDemande(paramId?:number, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/domaines_demande/" + paramId + "/",
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  listDomainesDemande(params:any, onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/domaines_demande/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  allDomainesDemande(params:any, onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/domaines_demande/all/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  addDomainesDemande(editParams:any, onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.postHttpHeaders() ;
    var request = this.httpClient.post<any>(
      environment.mainApiUrl + "/domaines_demande/",
      new HttpParams({ fromObject : editParams }),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  modifyDomainesDemande(paramId?:number, editParams?:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.postHttpHeaders() ;
    editParams._method = "PUT";
    var request = this.httpClient.put<any>(
      environment.mainApiUrl + "/domaines_demande/" + paramId + "/",
      new HttpParams({ fromObject : editParams }),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  deleteDomainesDemande(paramId?:number, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.postHttpHeaders() ;
    var editParams:any = { _method : "DELETE" };
    var request = this.httpClient.post<any>(
      environment.mainApiUrl + "/domaines_demande/" + paramId + "/",
      new HttpParams({ fromObject : editParams }),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  public listCadresDemande(params:any, onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/cadres_demande/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  public allCadresDemande(params:any, onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/cadres_demande/all/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  public detailsCadresDemande(paramId?:number, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/cadres_demande/" + paramId + "/",
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  addCadresDemande(editParams:any, onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.postHttpHeaders() ;
    var request = this.httpClient.post<any>(
      environment.mainApiUrl + "/cadres_demande/",
      new HttpParams({ fromObject : editParams }),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  modifyCadresDemande(paramId?:number, editParams?:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.postHttpHeaders() ;
    editParams._method = "PUT";
    var request = this.httpClient.put<any>(
      environment.mainApiUrl + "/cadres_demande/" + paramId + "/",
      new HttpParams({ fromObject : editParams }),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  deleteCadresDemande(paramId?:number, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.postHttpHeaders() ;
    var request = this.httpClient.delete<any>(
      environment.mainApiUrl + "/cadres_demande/" + paramId + "/",
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }

  
  

  public listTypesStructure(params:any, onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/types_structure/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  listDemandesFormation(params:any, onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/demandes_formation/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  detailsDemandeFormation(paramId?:number, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/demandes_formation/" + paramId + "/",
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  addDemandeFormation(form?:HTMLFormElement, onSuccess?:Function, onError?:Function) {
    if(form === undefined)
    {
      return
    }
    let formData = new FormData(form)
    var myheaders:HttpHeaders = this.uploadHttpHeaders() ;
    var request = this.httpClient.post<any>(
      environment.mainApiUrl + "/demandes_formation/",
      formData,
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  modifyDemandeFormation(paramId?:number, form?:HTMLFormElement, onSuccess?:Function, onError?:Function) {
    if(form === undefined)
    {
      return
    }
    var myheaders:HttpHeaders = this.uploadHttpHeaders() ;
    let formData = new FormData(form)
    var request = this.httpClient.put<any>(
      environment.mainApiUrl + "/demandes_formation/" + encodeURIComponent(paramId!.toString()) + "/",
      formData,
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  deleteDemandeFormation(paramId?:number, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.postHttpHeaders() ;
    var request = this.httpClient.delete<any>(
      environment.mainApiUrl + "/demandes_formation/" + paramId + "/",
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  listStructures(params:any, onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/structures/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  allStructures(params:any, onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/structures/all/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  detailsStructure(paramId?:number, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/structures/" + paramId + "/",
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  addStructure(form?:HTMLFormElement, onSuccess?:Function, onError?:Function) {
    // console.log(params);
    if(form === undefined)
    {
      return
    }
    var myheaders:HttpHeaders = this.uploadHttpHeaders() ;
    let formData = new FormData(form)
    var request = this.httpClient.post<any>(
      environment.mainApiUrl + "/structures/",
      formData,
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess!(data);
      },
      error: (error) => {
        onError!(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  modifyStructure(paramId?:number, form?:HTMLFormElement, onSuccess?:Function, onError?:Function) {
    if(form === undefined)
    {
      return
    }
    var myheaders:HttpHeaders = this.uploadHttpHeaders() ;
    let formData = new FormData(form)
    var request = this.httpClient.put<any>(
      environment.mainApiUrl + "/structures/" + paramId + "/",
      formData,
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  deleteStructure(paramId?:number, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.delete<any>(
      environment.mainApiUrl + "/structures/" + paramId + "/",
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }

  listModaliteFacturationSde(params:any, onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/modalites_facturation_sde/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  detailsModaliteFacturationSde(paramId?:number, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/modalites_facturation_sde/" + paramId + "/",
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  addModaliteFacturationSde(form?:HTMLFormElement, onSuccess?:Function, onError?:Function) {
    // console.log(params);
    if(form === undefined)
    {
      return
    }
    var myheaders:HttpHeaders = this.uploadHttpHeaders() ;
    let formData = new FormData(form)
    var request = this.httpClient.post<any>(
      environment.mainApiUrl + "/modalites_facturation_sde/",
      formData,
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess!(data);
      },
      error: (error) => {
        onError!(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  modifyModaliteFacturationSde(paramId?:number, form?:HTMLFormElement, onSuccess?:Function, onError?:Function) {
    if(form === undefined)
    {
      return
    }
    var myheaders:HttpHeaders = this.uploadHttpHeaders() ;
    let formData = new FormData(form)
    var request = this.httpClient.put<any>(
      environment.mainApiUrl + "/modalites_facturation_sde/" + paramId + "/",
      formData,
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  deleteModaliteFacturationSde(paramId?:number, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.delete<any>(
      environment.mainApiUrl + "/modalites_facturation_sde/" + paramId + "/",
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }

  listModaliteFacturationCde(params:any, onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/modalites_facturation_cde/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  detailsModaliteFacturationCde(paramId?:number, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/modalites_facturation_cde/" + paramId + "/",
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  addModaliteFacturationCde(form?:HTMLFormElement, onSuccess?:Function, onError?:Function) {
    // console.log(params);
    if(form === undefined)
    {
      return
    }
    var myheaders:HttpHeaders = this.uploadHttpHeaders() ;
    let formData = new FormData(form)
    var request = this.httpClient.post<any>(
      environment.mainApiUrl + "/modalites_facturation_cde/",
      formData,
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess!(data);
      },
      error: (error) => {
        onError!(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  modifyModaliteFacturationCde(paramId?:number, form?:HTMLFormElement, onSuccess?:Function, onError?:Function) {
    if(form === undefined)
    {
      return
    }
    var myheaders:HttpHeaders = this.uploadHttpHeaders() ;
    let formData = new FormData(form)
    var request = this.httpClient.put<any>(
      environment.mainApiUrl + "/modalites_facturation_cde/" + paramId + "/",
      formData,
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  deleteModaliteFacturationCde(paramId?:number, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.delete<any>(
      environment.mainApiUrl + "/modalites_facturation_cde/" + paramId + "/",
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }


  listApprenants(params:any, onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/apprenants/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  listFormateurs(params:any, onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/formateurs/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }



  listAgents(params:any, onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/agents/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  listRegions(params:any, onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/regions/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  addRegion(editParams:any, onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.postHttpHeaders() ;
    var request = this.httpClient.post<any>(
      environment.mainApiUrl + "/regions/",
      new HttpParams({ fromObject : editParams }),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  modifyRegion(paramId?:number, editParams?:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.postHttpHeaders() ;
    editParams._method = "PUT";
    var request = this.httpClient.put<any>(
      environment.mainApiUrl + "/regions/" + paramId + "/",
      new HttpParams({ fromObject : editParams }),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  deleteRegion(paramId?:number, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.postHttpHeaders() ;
    var editParams:any = { _method : "DELETE" };
    var request = this.httpClient.post<any>(
      environment.mainApiUrl + "/regions/" + paramId + "/",
      new HttpParams({ fromObject : editParams }),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  detailsAgent(paramId?:number, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/agents/" + paramId + "/",
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  addAgent(editParams:any, onSuccess:Function, onError:Function) {
    // console.log(params);
    var myheaders:HttpHeaders = this.postHttpHeaders() ;
    var request = this.httpClient.post<any>(
      environment.mainApiUrl + "/agents/",
      new HttpParams({ fromObject : editParams }),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  modifyAgent(paramId?:number, editParams?:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.postHttpHeaders() ;
    var request = this.httpClient.put<any>(
      environment.mainApiUrl + "/agents/" + paramId + "/",
      new HttpParams({ fromObject : editParams }),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  deleteAgent(paramId?:number, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.delete<any>(
      environment.mainApiUrl + "/agents/" + paramId + "/",
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  changePasswordAuthenticated(editParams?:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.postHttpHeaders() ;
    var request = this.httpClient.post<any>(
      environment.mainApiUrl + "/agents/authenticated_pwd/",
      new HttpParams({ fromObject : editParams }),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  listTypesDemande(params:any, onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/types_demande/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }

  
 
  listLieuxDeroulFormation(params:any, onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/lieux_deroule_formation/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  listProfilsApprenant(params:any, onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/profils_apprenant/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  listProfilsConsultant(params:any, onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/profils_consultant/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  listAnneesExperience(params:any, onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/annees_experience/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  listTypesPrestDemande(params:any, onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/types_prest_demande/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  listTranchesAge(params:any, onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/tranches_age/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  listPrerequisApprenant(params:any, onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/prerequis_apprenant/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  listDelaisRealiseDemande(params:any, onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/delais_realise_demande/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  listStructuresRealiseDemande(params:any, onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/structures/formatrices/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  listThemesFormation(params:any, onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/themes_formation/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  eventsThemesFormation(params:any, onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/themes_formation/events/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  detailsThemeFormation(paramId?:number, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/themes_formation/" + paramId + "/",
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  addThemeFormation(form?:HTMLFormElement, onSuccess?:Function, onError?:Function) {
    if(form === undefined)
    {
      return
    }
    let formData = new FormData(form)
    var myheaders:HttpHeaders = this.uploadHttpHeaders() ;
    var request = this.httpClient.post<any>(
      environment.mainApiUrl + "/themes_formation/",
      formData,
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  modifyThemeFormation(paramId?:number, form?:HTMLFormElement, onSuccess?:Function, onError?:Function) {
    if(form === undefined)
    {
      return
    }
    var myheaders:HttpHeaders = this.uploadHttpHeaders() ;
    let formData = new FormData(form)
    var request = this.httpClient.put<any>(
      environment.mainApiUrl + "/themes_formation/" + paramId + "/",
      formData,
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  clearThemeFormation(paramDemande:number, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.postHttpHeaders() ;
    var request = this.httpClient.delete<any>(
      environment.mainApiUrl + "/themes_formation/clear/?demande=" + encodeURIComponent(paramDemande.toString()),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  listLangues(params:any, onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/langues/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  addLangue(editParams:any, onSuccess:Function, onError:Function) {
    var myheaders:HttpHeaders = this.postHttpHeaders() ;
    var request = this.httpClient.post<any>(
      environment.mainApiUrl + "/langues/",
      new HttpParams({ fromObject : editParams }),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        onSuccess(data);
      },
      error: (error) => {
        onError(this.getErrorMsg(error), error);
      }
    });
    return request;
  }
  modifyLangue(paramId?:number, editParams?:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.postHttpHeaders() ;
    editParams._method = "PUT";
    var request = this.httpClient.put<any>(
      environment.mainApiUrl + "/langues/" + paramId + "/",
      new HttpParams({ fromObject : editParams }),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  deleteLangue(paramId?:number, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.postHttpHeaders() ;
    var editParams:any = { _method : "DELETE" };
    var request = this.httpClient.post<any>(
      environment.mainApiUrl + "/langues/" + paramId + "/",
      new HttpParams({ fromObject : editParams }),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  updateStatsDemandeFormation(paramId:number, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/demandes_formation/update_stats/?id=" + paramId,
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  ImportFichiersThemeFormation(paramId:number, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/themes_formation/import_fichiers/?id=" + paramId,
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  
  demandes_par_pays(onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/dashboard/demandes_par_pays/",
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  apprenants_par_annee(onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/dashboard/apprenants_par_annee/",
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  formateurs_par_annee(onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/dashboard/formateurs_par_annee/",
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  formations_par_mois(onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/dashboard/formations_par_mois/",
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  themes_par_mois(onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/dashboard/themes_par_mois/",
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  indicateurs_entites(onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/dashboard/indicateurs_entites/",
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  all_profils(onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/themes_formation/profils/",
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }

  stats_apprenants_liste_par_periode(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats/apprenants/liste_par_periode/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  stats_apprenants_repartition(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats/apprenants/repartition/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }

  stats_formations_nombre_par_periode(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats/formations/nombre_par_periode/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  stats_formations_repartition_demandees(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats/formations/repartition_demandees/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  stats_formations_nombre_prog_par_periode(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats/formations/nombre_prog_par_periode/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  stats_formations_repartition_programmees(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats/formations/repartition_programmees/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  stats_formations_nombre_real_par_periode(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats/formations/nombre_real_par_periode/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  stats_formations_repartition_realisees(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats/formations/repartition_realisees/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  stats_formateurs_liste_par_periode(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats/formateurs/liste_par_periode/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  stats_themes_formation_liste_par_periode(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats/themes_formation/liste_par_periode/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }

  stats_types_formation_liste_par_periode(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats/types_formation/liste_par_periode/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  stats_types_formation_repartition(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats/types_formation/repartition/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }

  stats_regions_liste_par_periode(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats/regions/liste_par_periode/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  stats_regions_pays_par_periode(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats/regions/pays_par_periode/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  stats_regions_langues_par_periode(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats/regions/langues_par_periode/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  stats_reseau_sde(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats/reseau/repartition_sde/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  stats_reseau_cde(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats/reseau/repartition_cde/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  stats_reseau_partenaire_financier(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats/reseau/repartition_fin/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  stats_reseau_udc(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats/reseau/repartition_udc/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  stats_langues_liste_par_periode(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats/langues/liste_par_periode/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }

  stats_couts_formation_subvention_fin(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats/couts_formation/subvention_fin/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }  
  stats_contrats_repartition_cde(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats/contrats/repartition_cde/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }  
  stats_contrats_repartition_sde(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats/contrats/repartition_sde/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  stats_kpi_personnes_formees(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats_kpi/personnes_formees/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  stats_kpi_lieux_deroul_formation(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats_kpi/lieux_deroul_formation/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  stats_kpi_cadres_demande(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats_kpi/cadres_demande/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  stats_kpi_subventions(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats_kpi/subventions/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  stats_kpi_pays(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats_kpi/pays/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  stats_kpi_hommes_jour(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats_kpi/hommes_jour/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  stats_kpi_profil_apprenant(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats_kpi/profil_apprenant/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  stats_kpi_tranche_age_apprenant(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats_kpi/tranche_age_apprenant/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  stats_kpi_repartition_sde(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats_kpi/repartition_sde/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  stats_kpi_prerequis_apprenant(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats_kpi/prerequis_apprenant/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  stats_kpi_repartition_cde(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats_kpi/repartition_cde/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  stats_kpi_formateurs_par_langue(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats_kpi/formateurs_par_langue/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  stats_kpi_profil_formateur(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats_kpi/profil_formateur/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  stats_kpi_anneeexp_formateur(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/stats_kpi/anneeexp_formateur/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
  refsPeriodes(params:any, onSuccess?:Function, onError?:Function) {
    var myheaders:HttpHeaders = this.getHttpHeaders() ;
    var request = this.httpClient.get<any>(
      environment.mainApiUrl + "/references/periodes/?" + this.toQueryString(params),
      {
        headers: myheaders
      }
    );
    request.subscribe({
      next: (data) => {
        if(onSuccess !== undefined) {
          onSuccess(data);
        }
      },
      error: (error) => {
        if(onError !== undefined) {
          onError(this.getErrorMsg(error), error);
        }
      }
    });
    return request;
  }
}
